<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  mounted() {
    // keyword

    if (this._isMobile()) {
        let url = `http://m.paipaiyou.com/#/`

        if (location.hash.replace("#/","")) {
          url = `http://m.paipaiyou.com/#/pages/${location.hash.replace("#/","").split('?')[0]}/${location.hash.replace("#/","")}`
        }
        location.href = url
    }
    
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    }
  }
}
</script>

<style lang="less">
@import './assets/css/index';
body {
  background: #f5f5f5 !important;
}
* {
  -webkit-user-select: auto;
}
</style>
